<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="header-marcar_consulta bg-gradient-primary">
          <b-row align-v="center">
            <b-col>
              <h1>
                <feather-icon icon="CalendarIcon" size="30" class="mr-50" />
                Marcar Consulta
              </h1>
              <!-- <h4 class="text-white">Inicie uma Aplicação de Jogo:</h4> -->
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="mb-2">
          <feather-icon icon="CheckCircleIcon" size="30" class="mr-50" />
          Selecione o Tipo de Consulta
        </h2>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4" md="6">
        <b-card
          class="card-consulta c-consulta-inicial"
          :class="{ selected: isActive('1') }"
          @click="selectType('1')"
        >
          <div class="check-corner" v-if="isActive('1')">
            <feather-icon
              icon="CheckCircleIcon"
              class="mr-50"
              size="40"
              color="white"
            />
          </div>
          <div class="img-consulta-sel">
            <img src="assets/images/modal_icons/1_consulta_inicial.png" />
          </div>
          <h3 class="txt-consulta-sel">Consulta Inicial</h3>
        </b-card>
      </b-col>
      <b-col lg="4" md="6">
        <b-card
          class="card-consulta c-consulta-com-apl apagado"
          :class="{ selected: isActive('5') }"
          @click="selectType('5')"
        >
          <div class="check-corner" v-if="isActive('5')">
            <feather-icon
              icon="CheckCircleIcon"
              class="mr-50"
              size="40"
              color="white"
            />
          </div>
          <div class="img-consulta-sel">
            <img src="assets/images/modal_icons/6_consulta_regular.png" />
          </div>
          <h3 class="txt-consulta-sel">Consulta Regular</h3>
        </b-card>
      </b-col>

      <b-col lg="4" md="6">
        <b-card
          class="card-consulta c-devolutiva apagado"
          :class="{ selected: isActive('6') }"
          @click="selectType('6')"
        >
          <div class="check-corner" v-if="isActive('6')">
            <feather-icon
              icon="CheckCircleIcon"
              class="mr-50"
              size="40"
              color="white"
            />
          </div>
          <div class="img-consulta-sel">
            <img src="assets/images/modal_icons/4_devolutiva.png" />
          </div>
          <h3 class="txt-consulta-sel">Consulta Final</h3>
        </b-card>
      </b-col>
    </b-row>
    <!-- <b-col cols="6" class="mb-2">
                  <h6>Consulta</h6>

                  <b-form-group>
                    <v-select
                      v-model="tipoConsulta_selecinado"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="tipoConsulta"
                    />
                  </b-form-group>  
                </b-col> -->

    <b-row>
      <b-col>
        <h2 class="mb-2">
          <feather-icon icon="LayersIcon" size="30" class="mr-50" />
          Selecione as aplicações da consulta
        </h2>
      </b-col>
    </b-row>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row class="match-height tipo-apl">
          <!-- <b-col lg="3" md="6">
            <b-card
              class="card-consulta c-consulta-inicial"
              :class="{ selected: isActive('1') }"
              @click="selectType('1')"
            >
              <div class="img-consulta-sel">
                <img src="assets/images/modal_icons/1_consulta_inicial.png" />
              </div>
              <h3 class="txt-consulta-sel">Consulta Inicial</h3>
            </b-card>
          </b-col> -->
          <b-col lg="3" md="6">
            <b-card
              class="card-consulta c-sem-aplicacao"
              :class="{ selected: isActive('0') }"
              @click="selectType('0')"
            >
              <div class="check-corner" v-if="isActive('0')">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                  size="40"
                  color="white"
                />
              </div>
              <div class="img-consulta-sel">
                <img src="assets/images/modal_icons/sem_aplicação.png" />
              </div>
              <h3 class="txt-consulta-sel">Nenhuma aplicação</h3>
            </b-card>
          </b-col>
          <b-col lg="3" md="6">
            <b-card
              class="card-consulta c-testes-cognitivos"
              :class="{ selected: isActive('2') }"
              @click="selectType('2')"
            >
              <div class="check-corner" v-if="isActive('2')">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                  size="40"
                  color="white"
                />
              </div>
              <div class="img-consulta-sel">
                <img src="assets/images/modal_icons/2_teste_cognitivos.png" />
              </div>
              <h3 class="txt-consulta-sel">Testes Cognitivos</h3>
            </b-card>
          </b-col>
          <b-col lg="3" md="6">
            <b-card
              class="card-consulta c-jogo-teste"
              :class="{ selected: isActive('3') }"
              @click="selectType('3')"
            >
              <div class="check-corner" v-if="isActive('3')">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                  size="40"
                  color="white"
                />
              </div>
              <div class="img-consulta-sel">
                <img src="assets/images/modal_icons/3_jogo_teste.png" />
              </div>
              <h3 class="txt-consulta-sel">Jogo Teste</h3>
            </b-card>
          </b-col>
          <b-col lg="3" md="6">
            <b-card
              class="card-consulta c-aplicacao-jogo"
              :class="{ selected: isActive('4') }"
              @click="selectType('4')"
            >
              <div class="check-corner" v-if="isActive('4')">
                <feather-icon
                  icon="CheckCircleIcon"
                  class="mr-50"
                  size="40"
                  color="white"
                />
              </div>
              <div class="img-consulta-sel">
                <img src="assets/images/modal_icons/7_aplicação do jogo.png" />
              </div>
              <h3 class="txt-consulta-sel">Aplicação do Jogo</h3>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <h2 class="mb-2">
              <feather-icon icon="UserCheckIcon" size="30" class="mr-50" />
              Paciente e disponibilidade
            </h2>
            <b-card>
              <b-row>
                <b-col cols="6" class="mb-2">
                  <!-- <h6>Profissional</h6>

                  <b-form-group>
                    <v-select
                      v-model="profissional_selecionado"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="profissionais"
                    />
                  </b-form-group> -->
                  <h6>Selecionar Paciente</h6>

                  <validation-provider
                    #default="{ errors }"
                    name="Paciente"
                    rules="required"
                  >
                    <b-form-group>
                      <v-select
                        v-model="form.patientId"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="pacientes"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <b-form-group
                    label="Localização da Consulta"
                    label-for="helperInput"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Endereço"
                      rules="required"
                    >
                      <b-form-input
                        id="helperInput"
                        v-model="form.location"
                        placeholder="Insira o Endereço da Consulta"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <label for="textarea-default">Orientações</label>
                  <b-form-textarea
                    id="textarea-default"
                    v-model="form.comments"
                    placeholder="Insira orientações para a consulta (opcional)"
                    rows="8"
                    max-rows="8"
                  />
                </b-col>

                <b-col cols="6" class="mb-2">
                  <h5 class="mb-2">Selecione a data e horário</h5>
                  {{ convertDateAndHour() }}

                  <validation-provider
                    #default="{ errors }"
                    name="Data"
                    rules="required"
                  >
                    <b-calendar
                      block
                      v-model="form.startDate"
                      label-close-button="Fechar"
                      label-no-date-selected="Selecione a data da consulta"
                      label-help="Clique para selecionar a data"
                      locale="locale"
                    />
                    <small class="text-danger d-block mb-2">{{
                      errors[0]
                    }}</small>
                  </validation-provider>

                  <label for="textarea-default">Horário de Início</label>

                  <validation-provider
                    #default="{ errors }"
                    name="Horário de Início"
                    rules="required"
                  >
                    <b-form-timepicker
                      v-model="startHour"
                      initial-date="initialDate"
                      label-no-time-selected="Selecione o horário de inicio"
                      label-close-button="Fechar"
                      size="lg"
                      locale="locale"
                    />
                    <small class="text-danger d-block mb-2">{{
                      errors[0]
                    }}</small>
                  </validation-provider>

                  <label for="textarea-default">Horário de Término</label>

                  <validation-provider
                    #default="{ errors }"
                    name="Horário de Término"
                    rules="required"
                  >
                    <b-form-timepicker
                      initial-time="initialDate"
                      label-no-time-selected="Selecione o horário de término"
                      label-close-button="Fechar"
                      v-model="endHour"
                      size="lg"
                      locale="locale"
                    />
                    <small class="text-danger d-block mb-2">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row class="button-right">
                <b-button
                  variant="primary"
                  @click="$router.push({ name: 'agenda' })"
                  class="m-1"
                >
                  Visualizar Agenda
                </b-button>
                <b-button variant="primary" class="m-1">
                  Limpar Dados
                </b-button>
                <b-button variant="primary" class="m-1" v-on:click="onSubmit()">
                  {{idConsulta ? 'Reagendar' : 'Efetuar Marcação'}}
                </b-button>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- <b-row>
        
        <b-col>
          <h2 class="mb-2">
      <feather-icon icon="UserIcon" size="30" class="mr-50" />
      Testes Cognitivos
    </h2>
          <b-card>
            
          
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        
        <b-col>
          <h2 class="mb-2">
      <feather-icon icon="MonitorIcon" size="30" class="mr-50" />
      Jogo Teste
    </h2>
          <b-card>
            
          
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        
        <b-col>
          <h2 class="mb-2">
      <feather-icon icon="MonitorIcon" size="30" class="mr-50" />
      Aplicação do jogo
    </h2>
          <b-card>
            
          
          </b-card>
        </b-col>
      </b-row> -->
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BMedia,
  BButton,
  BCalendar,
  BTime,
  BForm,
  BFormTimepicker,
  BFormTextarea,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import patientService from '@/services/patientService'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { hideSpinner, showSpinner } from '@/services/spinService'
import { cloneObject } from '@/@core/utils/utils'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment'
import medicalAppointmentService from '@/services/medicalAppointmentService'
import authService from '@/services/authService'

import { required, email, url } from '@validations'
import auth from '@/router/routes/auth'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BCalendar,
    BFormTimepicker,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: ['idConsulta'],
  data() {
    return {
      consulta: {},
      initialDate: moment().startOf('day').format('YYYY-MM-DD'),
      locale: 'pt_BR',
      labelDatePick: { labelHours: 'Horas' },
      isApplication: false,
      startHour: undefined,
      endHour: undefined,
      paciente_selecionado: undefined,
      profissional_selecionado: undefined,
      tipoConsulta_selecinado: undefined,

      pacientes: [],
      tipoConsulta: [
        { title: 'Consulta Inicial' },
        { title: 'Consulta de Aplicação' },
        { title: 'Consulta Final' },
      ],
      profissionais: [
        { title: 'Dra. Adriana' },
        { title: 'Dra. Dayane' },
        { title: 'Dra. Paloma' },
        { title: 'Dra. Iris' },
      ],
      form: {
        id: null,
        consultationTypeIds: ['1'],
        comments: undefined,
        location: undefined,
        patientId: undefined,
        startDate: undefined,
        endDate: undefined,
        login: authService.getUserLogged().username,
      },
    }
  },
  methods: {
    getConsultations(id) {
      const consultation = { consultationTypeId: id }
      if (id.toString() == '4') consultation['gameId'] = 1
      else if (id.toString() == '3') consultation['gameId'] = 3
      return consultation
    },
    validSelectedApplications() {
      return this.form.consultationTypeIds.length > 0
    },
    convertDateAndHour(hour) {
      return this.form.startDate && hour
        ? moment(`${this.form.startDate}T${hour}`).format('YYYY-MM-DD HH:mm')
        : undefined
    },
    prepareRequest(obj) {
      return {
        ...obj,
        id: this.idConsulta,
        consultations: obj.consultationTypeIds.map(consultation =>
          this.getConsultations(consultation)
        ),
        patientId: obj.patientId.id,
        startDate: this.convertDateAndHour(this.startHour),
        endDate: this.convertDateAndHour(this.endHour),
      }
    },
    async onSubmit() {
      if (
        this.validSelectedApplications() &&
        (await this.$refs.refFormObserver.validate())
      ) {
        
        medicalAppointmentService[this.idConsulta ? 'update' : 'create'](
          this.prepareRequest(cloneObject(this.form))
        )
          .then(res => {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Dados salvos com sucesso!',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            if (!this.id) {
              this.$router.push({ name: 'agenda' })
            }
          })
          .finally(() => {
            hideSpinner()
          })
      } else {
        this.$toast({
          component: ToastificationContentVue,
          position: 'top-right',
          props: {
            title: !this.validSelectedApplications()
              ? 'Selecione ao menos um tipo de consulta'
              : 'Verifique os campos!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    isActive(id) {
      if (id === '0') {
        return !this.form.consultationTypeIds.find(type =>
          ['2', '3', '4'].includes(type)
        )
      }
      return this.form.consultationTypeIds.find(type => type === id)
    },
    selectType(id) {
      if (id === '0') {
        this.form.consultationTypeIds = this.form.consultationTypeIds.filter(
          type => !['2', '3', '4'].includes(type)
        )
        return
      }
      if (['1', '6', '5'].includes(id)) {
        if (this.isActive(id)) {
          return
        }
        this.form.consultationTypeIds = this.form.consultationTypeIds.filter(
          type => !['1', '6', '5'].includes(type)
        )
      }
      this.addOrRemoveType(id)
    },
    addOrRemoveType(id) {
      const typeIndex = this.form.consultationTypeIds.findIndex(
        type => type === id
      )
      if (typeIndex >= 0) {
        this.form.consultationTypeIds = this.form.consultationTypeIds.filter(
          type => type !== id
        )
      } else {
        this.form.consultationTypeIds.push(id)
      }
    },
    async getPatients() {
      const pacientes = await patientService.getAll({})
      this.pacientes = pacientes.map(patient => ({
        id: patient.id,
        title: `${patient.physicalPerson.firstName.trim()} ${patient.physicalPerson.lastName.trim()}`,
      }))
    },
    async getConsulta() {
      await medicalAppointmentService.getById(this.idConsulta).then(res => {
        this.consulta = res
        this.form = {
          id: res.id,
          consultationTypeIds: res.consultationTypes.map(type => type.id.toString()),
          comments: res.comments,
          location: res.location,
          patientId: {
            id: res.patient.id,
            title: `${res.patient.physicalPerson.firstName.trim()} ${res.patient.physicalPerson.lastName.trim()}`,
          },
          startDate: moment(res.startDate).format('YYYY-MM-DD'),
          endDate: moment(res.endDate).format('YYYY-MM-DD'),
          login: authService.getUserLogged().username,
        }
        this.startHour = moment(res.startDate).format('HH:mm')
        this.endHour = moment(res.endDate).format('HH:mm')
      })
    },
  },
  async mounted() {
    this.getPatients()

    if (this.idConsulta) {
      await this.getConsulta()
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
